







import {Course} from '@/courses/models';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {defineComponent} from '@vue/composition-api';
import NotFound from '@/errors/views/NotFound.vue';
import {inject} from '@/container';
import CourseHierarchyServiceClass, {
  CourseHierarchyService,
} from '@/courses/services/CourseHierarchyServiceClass';
export default defineComponent({
  name: 'CourseWrapper',
  components: {NotFound},
  data() {
    return {courseExists: true};
  },
  computed: {
    hierarchyService(): CourseHierarchyServiceClass {
      return inject(CourseHierarchyService);
    },
    courseId(): string | number {
      return this.$route.params.courseId === 'new' ? 'new' : parseInt(this.$route.params.courseId);
    },
    course(): Course | null {
      if (this.isNew) {
        return null;
      } else {
        return Course.fullQuery().find(this.courseId);
      }
    },
    isReady(): boolean {
      return this.isNew || this.isConversationLoaded;
    },
    isNew(): boolean {
      return this.courseId === 'new';
    },
    isConversationLoaded(): boolean {
      return this.$loadingFlags.isLoaded(LoadingFlag.CourseWrapperCourse);
    },
    attrs(): any {
      return {
        ...this.$attrs,
        course: this.course,
        courseId: this.courseId,
      };
    },
  },
  watch: {
    courseId() {
      this.initializeCourse();
    },
  },
  created() {
    this.initializeCourse();
  },
  methods: {
    initializeCourse() {
      if (!this.isNew) {
        this.fetchCourseData();
      }
    },
    fetchCourseData() {
      this.$loadingFlags
        .loadingHandler(LoadingFlag.CourseWrapperCourse, () =>
          Promise.all([
            Course.api.get(this.courseId as number),
            !this.hierarchyService.getCourseId(this.courseId)
              ? this.hierarchyService.fetchForCourseLikeIds(this.courseId)
              : Promise.resolve(),
          ])
        )
        .then(() => (this.courseExists = true))
        .catch(() => (this.courseExists = false));
    },
  },
});
